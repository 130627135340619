import React, { useLayoutEffect } from "react"
import styled from "styled-components"

const Gleam = ({ url }) => {
  useLayoutEffect(() => {
    const script = document.createElement("script")

    script.src = "https://widget.gleamjs.io/e.js"
    // script.async = true

    document.body.appendChild(script)
  }, [])

  return (
    <StyledGleam>
      <a
        className="e-widget no-button"
        href="https://gleam.io/0Ou8j/deepwaters-nft-sweepstakes"
        rel="nofollow"
      >
        Deepwaters NFT Sweepstakes
      </a>
    </StyledGleam>
  )
}

const StyledGleam = styled.div`
  background: white;
  padding: var(--card-padding);
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);
  max-width: 540px;
  width: 100%;

  .e-widget-wrapper {
    box-shadow: none;
    max-width: auto;
    width: 100%;
  }
`

export default Gleam
