import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import Footer from "components/navigation/Footer"
import Nav from "components/navigation/Nav"
import { useContentfulSection, useMeta } from "utils"

import Gleam from "src/components/giveaway/Gleam"
import MobileNav from "src/components/navigation/mobile/MobileNav"

const GiveawayPage = ({ data: { giveaway } }) => {
  const {
    gleam_hero: { title, hat },
  } = useContentfulSection(giveaway.sections)

  const meta = useMeta(giveaway)

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />
      <MobileNav />

      <Wrap>
        <VSection
          titleAs="h1"
          title={title}
          hat={hat}
          custom={() => (
            <Gleam url="https://gleam.io/0Ou8j/deepwaters-nft-sweepstakes" />
          )}
        />
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 31;

  --section-max-width: 540px;
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);

  justify-content: center;
  --tg-text-align: center;

  .v_customContainer {
    margin-top: var(--sp-48);
  }
`

export const query = graphql`
  {
    giveaway: contentfulPage(pageId: { eq: "giveaway" }) {
      ...Page
    }
  }
`

export default GiveawayPage
